import React from 'react'
import Helmet from 'react-helmet'

export default function StructuredData({ children }) {
  if (!children) return null

	return (
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(children)}</script>
		</Helmet>
	)
}